<template>
  <div class="commodities">
    <div v-if="car != null">
      <div class="title" v-if="car">
        <p>Shopping Cart({{ car.length }})</p>
      </div>
      <div class="all d-flex">
        <!-- <section class="col-2">
          <input type="checkbox" v-model="checkAll" /> select all
        </section> -->
        <section class="col-7">Item</section>
        <section class="col-1">price</section>
        <section class="col-2">quantity</section>
        <section class="col-1">total</section>
        <section class="col-1">remove</section>
      </div>

      <div
        :class="item.check ? 'single-true' : ''"
        class="d-flex single"
        v-for="(item, index) of car"
      >
        <section class="single-check col-2 d-flex">
          <!-- <input type="checkbox" v-model="item.check" /> -->
          <div class="thumb">
            <img :src="item.thumb" alt="" />
          </div>
        </section>
        <section class="col-5 d-flex">
          <p class="col-7">{{ item.description }}</p>
          <p class="col-5 item-title1">{{ item.item_name }}</p>
        </section>
        <section class="col-1">${{ item.price }}</section>
        <section class="col-2">
          <div class="quantity d-flex">
            <button @click="subtract(index, item)">-</button>
            <span @click="open(item)">
              <input type="text" disabled v-model="item.num" />
            </span>

            <button @click="add(index, item)">+</button>
          </div>
        </section>
        <section class="col-1 count-totla">${{ item.total }}</section>
        <section class="remove col-1" @click="remove(item.id)">
          <img src="~assets/images/icon/remove.png" alt="" />
        </section>
      </div>

      <div class="count d-flex">
        <!-- <section class="count-check col-lg-4 col-5 d-flex">
          <input type="checkbox" v-model="checkAll" />
          <p>select all</p>
          <p>delete selected item</p>
        </section> -->
        <section class="col-7"></section>
        <section class="col-2" style="text-align: center">
          totla:$<span class="count-money">{{ count }}</span>
        </section>
        <section class="count-button col-3" @click="submit">Checkout</section>
      </div>
    </div>
    <div class="null" v-if="car == null">Cart is empty</div>
  </div>
</template>
<script>
import {
  getCartlist,
  plusOneCart,
  reduceOneCart,
  delCartByid,
  changeCartNumByid,
} from "network/addCart";

export default {
  name: "Commodities",
  // watch: {
  //   checkAll() {
  //     if (this.checkAll) {
  //       if (this.car != null) {
  //         for (let i = 0; i < this.car.length; i++) {
  //           this.car[i].check = true;
  //           this.num = this.car.length;
  //         }
  //       }
  //     } else {
  //       if (this.car != null) {
  //         for (let i = 0; i < this.car.length; i++) {
  //           this.car[i].check = false;
  //           this.num = 0;
  //         }
  //       }
  //     }
  //   },
  //   car: {
  //     handler(newVal, oldVal) {
  //       this.count = 0;
  //       this.num = 0;
  //       if (this.car != null) {
  //         for (let j = 0; j < this.car.length; j++) {
  //           this.car[j].total = Number(
  //             (this.car[j].price * 1000 * this.car[j].num) / 1000
  //           ).toFixed(2);
  //           if (this.car[j].check) {
  //             this.count = (
  //               Number(this.count) + Number(this.car[j].total)
  //             ).toFixed(2);
  //             this.num++;
  //           }
  //         }
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      checkAll: false,
      count: 0,
      num: 0,
      car: [],
    };
  },
  created() {
    this.sum();
  },
  methods: {
    sum() {
      getCartlist().then((res) => {
        this.car = res.data.data;
        if (res.data.data != null) {
          this.count = 0;
          for (let j = 0; j < this.car.length; j++) {
            this.count = (
              Number(this.count) + Number(this.car[j].total)
            ).toFixed(2);
          }
        }
      });
    },

    //弹窗填数字
    open(item) {
      this.$prompt("Enter the purchase quantity", {
        confirmButtonText: "yes",
        cancelButtonText: "no",
        inputErrorMessage: "Enter the correct quantity",
        inputType: "number",
      })
        .then(({ value }) => {
          if (Number(value) < item.min_num) {
            this.$notify({
              message: "Buy at least " + item.min_num,
              type: "error",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          } else {
            changeCartNumByid(item.id, Number(value)).then((res) => {
              if (res.data.code == 1) {
                this.$notify({
                  message: res.data.msg,
                  type: "success",
                  duration: 3000,
                  position: "top-left",
                  showClose: false,
                  offset: 300,
                });
                getCartlist().then((res) => {
                  this.car = res.data.data;
                });
              } else {
                this.$notify({
                  message: res.data.msg,
                  type: "error",
                  duration: 3000,
                  position: "top-left",
                  showClose: false,
                  offset: 300,
                });
              }
            });
            item.num = Number(value);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel the input",
          });
        });
    },
    //购物车减点击事件
    subtract(index, item) {
      if (this.car[index].num <= this.car[index].min_num) {
        this.$notify({
          message: "Buy at least " + this.car[index].min_num,
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        reduceOneCart(item.id).then((res) => {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        });
        // this.car[index].num--;
        getCartlist().then((res) => {
          this.car = res.data.data;
        });
        this.sum();
      }
    },
    //购物车加点击事件
    add(index, item) {
      plusOneCart(item.id).then((res) => {
        this.$notify({
          message: res.data.msg,
          type: "success",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        // this.car[index].num++;
        getCartlist().then((res) => {
          this.car = res.data.data;
        });
        this.sum();
      });
    },
    messageData() {
      return [this.car];
    },
    //购物车删除点击事件
    remove(id) {
      delCartByid(id).then((res) => {
        if (res.data.code == 1) {
          this.sum();
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
    //购物车提交点击事件
    submit() {
      let arr = "";
      if (this.car.length != null) {
        for (let i = 0; i < this.car.length; i++) {
          arr += this.car[i].id + ",";
        }
        arr = arr.substr(0, arr.length - 1);

        if (arr != "") {
          this.$router.push({
            path: "/order",
            query: {
              arr,
            },
          });
        } else {
          return;
        }
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.commodities {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.title {
  display: flex;
  font-size: 0.6rem;
  color: #ff7f00;
  align-items: center;
  font-weight: 600;
}
.title p {
  padding: 0.25rem 0;
  margin: 0 0.25rem;
  border-bottom: 2px solid #ff7f00;
}
.all {
  padding: 0.25rem;
  background: #f2f2f2;
  font-size: 0.35rem;
  color: #666666;
}
.all section {
  padding: 0;
  margin: 0;
}
.single section {
  padding: 0;
  margin: 0;
}
.single p {
  padding: 0;
  margin: 0;
}
.single {
  font-size: 0.35rem;
  border-bottom: 1px solid #ededed;
  padding: 0.25rem;
}
.single-true {
  background: #ffecd9;
}
.single-check {
  align-items: center;
}
.thumb {
  padding: 0.25rem;
  width: 100%;
  height: 4rem;
}
.thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.quantity button:first-child {
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.25rem;
  border-radius: 0.1rem 0 0 0.1rem;
}
.quantity button:last-child {
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.25rem;
  border-radius: 0 0.1rem 0.1rem 0;
}
.quantity input {
  width: 1rem;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  height: 100%;
}
.remove img {
  cursor: pointer;
}

.count {
  margin: 0;
  padding: 0 0 0 0.25rem;
  font-size: 0.35rem;
  border-top: 1px solid #ededed;
  margin-top: 1rem;
  color: #666666;
}
.count section {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
}
.count-check {
  align-items: center;
}
.count-check p {
  margin-left: 0.25rem;
}
.count-button {
  background: #ff7f00;
  color: #fff;
  cursor: pointer;
  justify-content: center;
}
.count-money {
  color: #de0e12;
  font-size: 0.5rem;
}

.count-totla {
  font-weight: bold;
}
.item-title1 {
  color: #999999;
}
.null {
  padding: 0.5rem 0.25rem;
  font-size: 0.5rem;
}
</style>
